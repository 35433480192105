import axiosClient from "./AxiosConfig";

export default {
  cleanupRefreshTokens() {
    return axiosClient.post("/maintenance/refreshtokens").then(response => {
      return response.data;
    });
  },

  updateNamiData() {
    return axiosClient.post("/maintenance/namidata").then(response => {
      return response.data;
    });
  }
};
