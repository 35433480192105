import axiosClient from "./AxiosConfig";

export default {
  getApplicationPublicKey() {
    return axiosClient
      .get("/push-notifications/application-public-key")
      .then(response => {
        return response.data;
      });
  },
  storeSubscription(subscription) {
    return axiosClient
      .post("/push-notifications/subscription", subscription)
      .then(response => {
        return response.data;
      });
  },
  async deleteSubscription(endpoint) {
    return await axiosClient.delete("/push-notifications/subscription", {
      data: { endpoint: endpoint }
    });
  }
};
