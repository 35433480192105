<template>
  <div>
    <v-row class="justify-center mx-0">
      <v-col>
        <v-row class="justify-center mx-0">
          <v-card width="500" class="mx-1 mb-4">
            <v-card-title>
              Updates
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn
                      color="success"
                      @click="refreshApp()"
                      v-if="updateAvailable"
                      text
                    >
                      Update
                    </v-btn>
                    <v-icon v-else>
                      check
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{
                      updateAvailable
                        ? "Neues Update verfügbar"
                        : "Anwendung aktuell"
                    }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="justify-center mx-0">
          <v-card width="500" class="mx-1 mb-4">
            <v-card-title>
              Benachrichtungen
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-3" v-bind="attrs" v-on="on">
                    info
                  </v-icon>
                </template>
                <div>
                  Benachrichtungen sind in einem frühen Entwicklungsstand.
                </div>
                <div>
                  Vorerst wirst du nur bei der Aktivierung eine einzelne
                  Push-Benachrichtung erhalten.
                </div>
              </v-tooltip>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>
                      sms
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    Push-Benachrichtungen erlauben
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="notificationPermissionToggle"
                      @change="updateNotificationPermission()"
                    />
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="justify-center mx-0">
          <v-card width="500" class="mx-1 mb-4 mt-1">
            <v-card-title>
              Lokale Daten
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list>
                <v-list-item @click="deleteAccessToken()">
                  <v-list-item-action>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <div>Lösche <b>Access Token</b></div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="clearLocalStorage()">
                  <v-list-item-action>
                    <v-icon>
                      delete
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <div>
                      Lösche <b>alle persönlichen Daten</b>, die im Browser
                      gespeichert werden
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="justify-center mx-0">
          <v-card width="500" class="mx-1 mb-4">
            <v-card-title>
              User Interface
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list>
                <v-list-item @click="setDarkMode(!darkMode)">
                  <v-list-item-action>
                    <v-icon>
                      {{ darkMode ? "brightness_5" : "brightness_4" }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{
                      darkMode
                        ? "Wechsel zu Light Mode"
                        : "Wechsel zu Dark Mode"
                    }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="justify-center mx-0">
          <v-card width="500" class="mx-1 mb-4" v-if="isAdmin">
            <v-card-title>
              Server Wartung
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-list>
                <v-list-item @click="updateNamiData()">
                  <v-list-item-action>
                    <v-icon>
                      update
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    Nami-Daten aktualisieren
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="cleanupRefreshTokens()">
                  <v-list-item-action>
                    <v-icon>
                      autorenew
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    Lösche ungültige Refreshtokens
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbarShow" :timeout="6000" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn icon @click="snackbarShow = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MaintenanceAPI from "../api/MaintenanceAPI";
import NotificationsAPI from "@/api/NotificationsAPI";

export default {
  name: "Settings.vue",
  data() {
    return {
      snackbarShow: false,
      snackbarText: "",
      snackbarColor: "info",
      notificationPermissionToggle: false
    };
  },
  methods: {
    ...mapMutations("user", ["deleteAccessToken"]),
    ...mapActions("settings", ["setDarkMode"]),
    ...mapActions("pwa", ["refreshApp"]),
    ...mapActions("snackbar", ["showSnackbar"]),
    ...mapActions("users", ["refreshUsers"]),
    async updateNotificationPermission() {
      if (this.notificationPermissionToggle) {
        const permission = await window.Notification.requestPermission();
        if (permission !== "granted") {
          this.notificationPermissionToggle = false;
        } else {
          await this.subscribePushNotifications();
        }
      } else {
        await this.unsubscribePushNotifications();
      }
    },
    async subscribePushNotifications() {
      let response = await NotificationsAPI.getApplicationPublicKey();
      navigator.serviceWorker.ready.then(registration => {
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: response.applicationPublicKey
          })
          .then(subscription => {
            NotificationsAPI.storeSubscription({
              endpoint: subscription.endpoint,
              keys: subscription.toJSON().keys
            }).catch(() => {
              this.notificationPermissionToggle = false;
              this.showSnackbar({
                text: "Push-Nachrichten konnten leider nicht aktiviert werden",
                color: "error"
              });
            });
          })
          .catch(error => {
            console.log(error);
          });
      });
    },
    async unsubscribePushNotifications() {
      navigator.serviceWorker.ready
        .then(async registration => {
          let sub = await registration.pushManager.getSubscription();
          console.log(sub);
          sub.unsubscribe().then(success => {
            if (success) {
              NotificationsAPI.deleteSubscription(sub.endpoint);
            } else {
              this.notificationPermissionToggle = true;
              this.showSnackbar({
                text: "Push-Nachrichten deaktivieren fehlgeschlagen",
                color: "error"
              });
            }
          });
        })
        .catch(error => {
          this.notificationPermissionToggle = true;
          console.log(error);
          this.showSnackbar({
            text: "Push-Nachrichten deaktivieren fehlgeschlagen",
            color: "error"
          });
        });
    },
    clearLocalStorage() {
      localStorage.clear();
    },
    updateNamiData() {
      MaintenanceAPI.updateNamiData()
        .then(response => {
          this.refreshUsers();
          this.showSnackbar({
            text: response
          });
        })
        .catch(error => {
          let errorMessage = "";
          if (error.message === "Network Error") {
            errorMessage = error.message;
          } else {
            errorMessage = error.response.data;
          }
          this.showSnackbar({
            text: errorMessage,
            color: "error"
          });
        });
    },
    cleanupRefreshTokens() {
      MaintenanceAPI.cleanupRefreshTokens()
        .then(response => {
          this.showSnackbar({
            text: response
          });
        })
        .catch(error => {
          let errorMessage = "";
          if (error.message === "Network Error") {
            errorMessage = error.message;
          } else {
            errorMessage = error.response.data;
          }
          this.showSnackbar({
            text: errorMessage,
            color: "error"
          });
        });
    }
  },
  computed: {
    updateAvailable() {
      return this.$store.state.pwa.updateAvailable;
    },
    darkMode() {
      return this.$store.state.settings.darkMode;
    },
    ...mapGetters("user", ["isAdmin"])
  },
  mounted() {
    navigator.serviceWorker.ready.then(async registration => {
      let sub = await registration.pushManager.getSubscription();
      if (sub) {
        this.notificationPermissionToggle = true;
      }
    });
  }
};
</script>

<style scoped></style>
